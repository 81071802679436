import React from "react";
import { Layout } from "../../../../src/components";
import CartItemsContainer from "../../components/cart/cart-items-container";

const Cart = () => {
  return (
    <Layout>
      <div className="layout">
        <h1>Cart</h1>
        <CartItemsContainer />
      </div>
    </Layout>
  );
};

export default Cart;
