import Link from "gatsby-link";
import React, { useContext, useEffect, useState } from "react";
import { v4 } from "uuid";
import { useMutation, useQuery } from "@apollo/client";
import { AppContext } from "../../context/AppContext";
import { getFormattedCart, getUpdatedItems } from "../../../utils/functions";
import CartItem from "../cart-item";
import UPDATE_CART from "../../../mutations/update-cart";
import GET_CART from "../../../queries/get-cart";
import CLEAR_CART_MUTATION from "../../../mutations/clear-cart";

import "./style.scss";

const CartItemsContainer = () => {
  const { cart, setCart } = useContext(AppContext);
  const [requestError, setRequestError] = useState(null);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   // const updatedCart = getFormattedCart(data);
  //   // localStorage.setItem("woo-cart", JSON.stringify(updatedCart));
  //   //refetch();
  //   setLoading(false);
  // }, []);

  // Get Cart Data.
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      // console.warn( 'completed GET_CART', data );

      // Update cart in the localStorage.
      const updatedCart = getFormattedCart(data);
      // Update cart data in React Context.
      if (updatedCart) {
        localStorage.setItem("woo-cart", JSON.stringify(updatedCart));
        setCart(updatedCart);
        setLoading(false);
      }
    },
  });

  // Update Cart Mutation.
  const [updateCart, { loading: updateCartProcessing }] = useMutation(UPDATE_CART, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      if (error) {
        //console.log('Error013:',error);
        localStorage.removeItem("woo-cart");
        setCart({});
        setLoading(false);
        //setRequestError(error.graphQLErrors);
      }
    },
  });

  // Update Cart Mutation.
  const [clearCart, { loading: clearCartProcessing }] = useMutation(CLEAR_CART_MUTATION, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      if (error) {
        //console.log('error012:',error);
        localStorage.removeItem("woo-cart");
        setCart({});
        setLoading(false);
        //setRequestError(error.graphQLErrors[0].message);
      }
    },
  });

  /*
   * Handle remove product click.
   *
   * @param {Object} event event
   * @param {Integer} Product Id.
   *
   * @return {void}
   */
  const handleRemoveProductClick = (event, cartKey, products) => {
    event.stopPropagation();
    //setLoading(true);
    if (products.length) {
      // By passing the newQty to 0 in updateCart Mutation, it will remove the item.
      const newQty = 0;
      const updatedItems = getUpdatedItems(products, newQty, cartKey);
      //setCart(updatedItems);
      //setLoading(true);
      updateCart({
        variables: {
          input: {
            clientMutationId: v4(),
            items: updatedItems,
          },
        },
      });
    }
  };

  // Clear the entire cart.
  const handleClearCart = (event) => {
    event.stopPropagation();
    setLoading(true);
    if (clearCartProcessing) {
      return;
    }

    clearCart({
      variables: {
        input: {
          clientMutationId: v4(),
          all: true,
        },
      },
    });
    setCart({});
    setLoading(false);
  };

  //console.log('cart:',cart)

  return (
    <div className="cart-content-wrap">
      {cart?.products?.length > 0 ? (
        <div className="container-wrapper">
          {loading && <div className="loading">Loading...</div>}
          <div className="cart-wrapper">
            <div className="product-list">
              {cart?.products?.map((item, index) => (
                <CartItem
                  key={index}
                  item={item}
                  updateCartProcessing={updateCartProcessing}
                  products={cart.products}
                  handleRemoveProductClick={handleRemoveProductClick}
                  updateCart={updateCart}
                />
              ))}

              {/*Clear entire cart*/}
              {!loading && (
                <div className="clear-cart">
                  <button className="action" onClick={(event) => handleClearCart(event)} disabled={clearCartProcessing}>
                    <span className="woo-next-cart">Clear Cart</span>
                    <i className="fa fa-arrow-alt-right" />
                  </button>
                  {clearCartProcessing ? <p>Clearing...</p> : ""}
                </div>
              )}
            </div>

            {/* Display Errors if any */}
            {requestError ? <div className="totals-container"> {requestError} </div> : ""}

            {/*Cart Total*/}
            <div className="totals-container">
              <h2>Cart Total</h2>
              <table className="table table-hover">
                <tbody>
                  <tr className="table-light">
                    <td className="woo-next-cart-element-total">Subtotal</td>
                    <td className="woo-next-cart-element-amt">
                      {"string" !== typeof cart?.totalProductsPrice
                        ? cart?.totalProductsPrice?.toFixed(2)
                        : cart?.totalProductsPrice}
                    </td>
                  </tr>
                  <tr className="table-light">
                    <td className="woo-next-cart-element-total">Total</td>
                    <td className="woo-next-cart-element-amt">
                      {"string" !== typeof cart?.totalProductsPrice
                        ? cart?.totalProductsPrice?.toFixed(2)
                        : cart?.totalProductsPrice}
                    </td>
                  </tr>
                </tbody>
              </table>
              <Link to="/checkout/" className="action">
                Proceed to Checkout
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <p>No items in the cart</p>
          <Link to="/shop/">
            <button className="action">Add New Products</button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default CartItemsContainer;
